import _ from 'lodash'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Wrapper, H1, Spinner, Navbar } from '../../components'
import img from '../../assets/images/reflections-group-leaves-2.png'
import Seo from "../../components/Seo";
import { useTranslation } from 'react-i18next'

const Text = ({ className, text }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: text }}></div>
)

const ImageBackground = styled.img`
  width: 100%;
  position: absolute;
  max-width: 432px;
  top: 423px;
`

const PageView = ({
    loadingHome,
    data,
}) => {

    console.log("PAGELOADING", loadingHome)
	const { t } = useTranslation();

  if (loadingHome) {
    return <Spinner loading={loadingHome} />
  }

  return (
	<>
		<Seo title="Kamo - Home" yoast={_.get(data, "yoast_head_json")} />
		<Navbar
				title={t('booking.titleSection')}
				loading={loadingHome}
				t={t}
			/>
		<Wrapper>
			<ImageBackground src={img} />
			<Wrapper
				mwidth={{ d: 'calc(1024px - 32px)', l: 'calc(1240px - 32px)' }}
				padding={{ m: '10px 20px', d: '40px 0 0' }}>
				<Wrapper
				mwidth={{ d: '1' }}
				flexDirection={{ m: 'column', d: 'column' }}>
				<Wrapper alignItems={{ m: 'flex-start' }} margin={{ d: '0 0 48px' }}>
					<H1>{_.get(data, 'title.rendered')}</H1>
				</Wrapper>
				<Wrapper>
					<Text text={_.get(data, 'content.rendered')} className="rich-text"/>
				</Wrapper>
				</Wrapper>
			</Wrapper>
		</Wrapper>
	</>

  )
}

export default PageView
