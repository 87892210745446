import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import PageView from './page-view'

const PageController = (props) => {
  const { pathname } = props.location
  let slugParts = pathname.substr(0, (pathname.length - 1))
  slugParts = slugParts.split('/');
  console.log(slugParts);
  const slug = slugParts.length > 1 ? slugParts[slugParts.length - 1] : slugParts[0];
//   const slug = _.replace(pathname, '/', '')
console.log('PAGESLUG', slug);
  const [page, setPage] = useState([])
  const [loadingHome, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const getTerms = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: !i18n.language.toLowerCase().includes('en') ? {
        lang: i18n.language.toLowerCase(),
        slug,
      } : { 
        slug
       },
    }).then((resp) => {
      setPage(resp[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getTerms()
  }, [i18n.language])

  const viewProps = {

    loadingHome,
    data: page,
    t,
  }
  return (
    <PageView {...viewProps} />
  )
}

export default PageController
